/**
 * @generated SignedSource<<f6327e64780d315431847c9a4a5c3066>>
 * @relayHash 057b1d08a27f38e7ba96a3320eab8da1
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 057b1d08a27f38e7ba96a3320eab8da1

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type IssueClosedStateReason = "COMPLETED" | "DUPLICATE" | "NOT_PLANNED" | "%future added value";
export type IssueState = "CLOSED" | "OPEN" | "%future added value";
export type IssueStateReason = "COMPLETED" | "DUPLICATE" | "NOT_PLANNED" | "REOPENED" | "%future added value";
export type updateIssueStateMutationCloseMutation$variables = {
  duplicateIssueOrPullRequestId?: string | null | undefined;
  id: string;
  newStateReason: IssueClosedStateReason;
};
export type updateIssueStateMutationCloseMutation$data = {
  readonly closeIssue: {
    readonly issue: {
      readonly id: string;
      readonly state: IssueState;
      readonly stateReason: IssueStateReason | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type updateIssueStateMutationCloseMutation$rawResponse = {
  readonly closeIssue: {
    readonly issue: {
      readonly id: string;
      readonly state: IssueState;
      readonly stateReason: IssueStateReason | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type updateIssueStateMutationCloseMutation = {
  rawResponse: updateIssueStateMutationCloseMutation$rawResponse;
  response: updateIssueStateMutationCloseMutation$data;
  variables: updateIssueStateMutationCloseMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "duplicateIssueOrPullRequestId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "newStateReason"
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "duplicateIssueOrPullRequestId",
            "variableName": "duplicateIssueOrPullRequestId"
          },
          {
            "kind": "Variable",
            "name": "issueId",
            "variableName": "id"
          },
          {
            "kind": "Variable",
            "name": "stateReason",
            "variableName": "newStateReason"
          }
        ],
        "kind": "ObjectValue",
        "name": "input"
      }
    ],
    "concreteType": "CloseIssuePayload",
    "kind": "LinkedField",
    "name": "closeIssue",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Issue",
        "kind": "LinkedField",
        "name": "issue",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "state",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "stateReason",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "updateIssueStateMutationCloseMutation",
    "selections": (v3/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "updateIssueStateMutationCloseMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "id": "057b1d08a27f38e7ba96a3320eab8da1",
    "metadata": {},
    "name": "updateIssueStateMutationCloseMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "8ebe1c824be46d588ea00cd2e5fe388a";

export default node;
